































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import { DpBackendCustomersSearchOneCustomerDto } from '@/proxy/models'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
@Component({
  name: 'MergeCustomerDrawer',
  components: {
    ListTile,
    LensSpan,
    DrawerHeader
  }
})
export default class extends Vue {
    @Prop({ default: '' }) private visible!: boolean
    @Prop({ default: '' }) private showMergePerson!: boolean
    @Prop({ default: '' }) private mergePhone!: string
    @Prop({ default: '' }) private mergeData!: DpBackendCustomersSearchOneCustomerDto
    @Prop({ default: '' }) private customerId!: string

    private showMergeDrawer = false

    private saveMerge() {
      this.$emit('onSaveMerge')
    }

    private onDrawerClose() {
      this.$emit('onMergeDrawerHide', this.showMergeDrawer)
    }
}
