































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import Divider from '@/components/Divider/index.vue'
import { ICustomerData, IDictData, ITagData } from '@/api/types'
import TextUtil from '@/utils/text'
import TagSettingsDrawer from '@/views/wecom/mobile-entry/drawers/tag-settings/index.vue'
import CustomerApi from '@/api/customer'
import SystemApi from '@/api/system'
import Constant, { TagType } from '@/constant/constant'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import { IContactData, IEnumData } from '@/constant/models'
import MobileContactInput from '@/views/wecom/mobile-entry/components/ContactInput/index.vue'
import EditableSpan from '@/components/EditableSpan/index.vue'
import SelectableSpan from '@/components/SelectableSpan/index.vue'
import EditableRemark from '@/components/EditableRemark/index.vue'
import VuePicker from '@/components/VuePicker/src/index.vue'
import cityDataJson from '@/assets/address.json'
import ObjectUtil from '@/utils/object'
import DateUtil from '@/utils/date'
import PermissionConst from '@/constant/permissions'
import { UserModule } from '@/store/modules/user'
import MobileMergeDrawer from '@/views/wecom/mobile-entry/customer-profile/merge/components/index.vue'
import { DpBackendCustomersSearchOneCustomerDto } from '@/proxy/models'
import { CustomerApi as ProxyCustomerApi, WebCallApi } from '@/proxy'

const freezeData = ObjectUtil.deepFreeze(cityDataJson)
@Component({
  name: 'MobileCustomProfile',
  components: {
    EditableRemark,
    SelectableSpan,
    EditableSpan,
    MobileContactInput,
    TagSettingsDrawer,
    Divider,
    ListTile,
    VuePicker,
    MobileMergeDrawer
  }
})
export default class extends Vue {
  @Prop({ default: '' }) private customerId!: string
  private customerInfo: ICustomerData = {
    contactWayDictIds: [],
    contactWayTitles: [],
    contactWays: [],
    tags: [],
    identity: {
      list: []
    },
    id: '',
    name: ''
  }

  @Watch('customerId', { immediate: true })
  private async onCustomerIdChanged(id: string, oldId: string) {
    if (id !== oldId && TextUtil.isNotEmpty(id)) {
      const res = await CustomerApi.customerDetail(id)
      if (res.data) {
        this.customerInfo = res.data
        this.contactList = []
        ArrayUtil.filter<string>(this.customerInfo.contactWayTitles).forEach((title, index) => {
          this.contactList.push({
            value: ArrayUtil.filter<string>(this.customerInfo.contactWays)[index],
            isEdit: false,
            isNew: false,
            editValue: '',
            dictId: ArrayUtil.filter<string>(this.customerInfo.contactWayDictIds)[index],
            methodName: title
          })
        })
        this.$emit('customerDataUpdate', this.customerInfo)
      }
    }
  }

  private showCityPicker = false
  private showGenderPicker = false

  private showMergeDrawer = false
  private showMerge = false
  private mergeData: DpBackendCustomersSearchOneCustomerDto = {}
  private mergePhoneData = ''

  private genderTypes = Constant.GenderType

  private isTagSettingsShow = false

  private contactMethods: IDictData[] = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
  private contactList: IContactData[] = []
  private cityData = freezeData

  created() {
    DateUtil.generateDateData()
  }

  private get showAddContact() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.AddContactWay)
  }

  private handleTagSettingsClose(done: Function) {
    done()
  }

  private deleteContact(index: number) {
    this.$confirm('是否确认删除该联系方式?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async() => {
      const tempList: IContactData[] = this.contactList.concat()
      tempList.splice(index, 1)
      await this.requestEditContact(tempList)
    })
  }

  private cancelEdit(index: number) {
    this.contactList.splice(index, 1)
  }

  private async saveContact(index: number, editContact: IContactData) {
    const tempList: IContactData[] = this.contactList.concat()
    if (editContact.error) {
      this.$message.warning(editContact.error)
      this.$set(this.contactList, index, {
        ...editContact,
        isEdit: true
      })
      return
    }
    editContact.isEdit = false
    editContact.isNew = false
    tempList[index] = editContact
    await this.requestEditContact(tempList)
  }

  private addNewContact() {
    if (this.contactMethods.length === 0) {
      this.$message.warning(Constant.Warnings.InvalidData)
      return
    }
    this.contactList.push({
      value: '',
      isEdit: true,
      isNew: true,
      editValue: '',
      dictId: this.contactMethods[0].id,
      methodName: this.contactMethods[0].name
    })
  }

  private async onCustomerNameUpdate(name: string) {
    if (!this.customerInfo.id) {
      return
    }
    const { status } = await CustomerApi.editCustomerName(this.customerInfo.id, name)
    if (status === 204) {
      this.customerInfo.name = name
    }
  }

  private async onCustomerGenderUpdate(res: IEnumData[]) {
    if (!this.customerInfo.id || !res[0]) {
      return
    }
    const gender = res[0]
    const { status } = await CustomerApi.editCustomerGender(this.customerInfo.id, gender.value)
    if (status === 204) {
      this.customerInfo.gender = gender.value
    }
  }

  private async onCustomerCityUpdate(res: IEnumData[]) {
    if (!this.customerInfo.id || res.length !== 2) {
      return
    }
    const city = `${res[0].label}-${res[1].label}`
    console.log('select city', city)
  }

  private async onEditRemarkConfirm(editRemark: string) {
    if (!editRemark || !this.customerInfo.id) {
      return
    }
    const { status } = await CustomerApi.editCustomerRemark(this.customerInfo.id, editRemark)
    if (status === 204) {
      this.customerInfo.remark = editRemark
    }
  }

  private async requestEditContact(
    contactList: IContactData[]
  ) {
    if (!this.customerInfo.id) {
      return
    }
    const { status } = await CustomerApi.editCustomerContact(this.customerInfo.id, contactList)
    if (status === 204) {
      this.contactList = contactList
    }
  }

  private async onTagConfirm(tags: ITagData[]) {
    if (tags.length === 0 || !this.customerInfo.id) {
      return
    }
    const { data } = await SystemApi.setTag(
      TagType.Customer,
      [this.customerInfo.id],
      tags.map(tag => tag.id)
    )
    if (data) {
      this.customerInfo.tags = tags
    }
  }

  private showTagSettings() {
    this.isTagSettingsShow = true
  }

  private async onMergeDrawerShow(val: number) {
    const results = await new ProxyCustomerApi().apiAppCustomerSearchOneGet(this.customerInfo.contactWays[val])
    this.showMergeDrawer = true
    if (results.data) {
      this.showMerge = false
      this.mergeData = results.data
    } else {
      this.mergePhoneData = this.customerInfo.contactWays[val]
      this.showMerge = true
    }
  }

  private onMergeDrawerHide() {
    this.showMergeDrawer = false
  }

  private async onSaveMerge() {
    const results = await new ProxyCustomerApi().apiAppCustomerIdMergePost(this.customerId, { id: this.mergeData.id })
    if (results.data) {
      this.$message.success('合并客户成功')
      this.onMergeDrawerHide()
      window.location.reload()
    }
  }

  private async callPhone(number: string) {
    const { data } = await new WebCallApi().apiAppWebCallCallPost({
      customerId: this.customerId,
      customerMobile: number
    })
    if (!data?.isOk) {
      this.$message.error(data.message)
    }
  }
}
