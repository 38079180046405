





















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import ListTile from '@/components/ListTile/index.vue'
import { IOrderData } from '@/api/types'
import TextUtil from '@/utils/text'
import MarketingApi from '@/api/marketing'
import { handleClipboard } from '@/utils/clipboard'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
// todo 缺字段
@Component({
  name: 'OrderDetailDrawer',
  components: {
    MobileProductItem,
    ListTile,
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  private handleClipboard = handleClipboard
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private orderId!: string

  private order: IOrderData = {
    products: []
  }

  @Watch('orderId', { immediate: true })
  private onOrderChanged(id: string, oldId: string) {
    if (id !== oldId && TextUtil.isNotEmpty(id)) {
      this.refreshOrderDetail()
    }
  }

  private async refreshOrderDetail() {
    if (!this.orderId) {
      return
    }
    const { data } = await MarketingApi.orderDetail(this.orderId)
    if (data) {
      this.order = data
    }
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }
}
