























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import Divider from '@/components/Divider/index.vue'
import TextUtil from '@/utils/text'
import WecomApi from '@/api/wecom'
import { ITagData, IWecomCustomerData } from '@/api/types'
import WecomUtil from '@/utils/wecom'
import TagSettingsDrawer from '@/views/wecom/mobile-entry/drawers/tag-settings/index.vue'
import SystemApi from '@/api/system'
import Constant, { TagType } from '@/constant/constant'
import ObjectUtil from '@/utils/object'
import LensSpan from '@/components/LensSpan/index.vue'

@Component({
  name: 'MobileWecomProfile',
  components: {
    LensSpan,
    TagSettingsDrawer,
    ListTile,
    Divider
  }
})
export default class extends Vue {
  @Prop({ default: '' }) private userId!: string
  private wecomCustomerData: IWecomCustomerData = {
    followUsers: [],
    tags: [],
    id: '',
    mobiles: []
  }

  @Watch('userId', { immediate: true })
  private async onUserIdChanged(userId: string, oldUserId: string) {
    if (userId !== oldUserId && TextUtil.isNotEmpty(userId)) {
      const res = await WecomApi.wecomCustomerInfo(userId)
      if (res.data) {
        this.wecomCustomerData = res.data
        this.$emit('customerDataUpdate', this.wecomCustomerData)
      }
    }
  }

  private isTagSettingsShow = false

  private handleTagSettingsClose(done: Function) {
    done()
  }

  private showTagSettings() {
    this.isTagSettingsShow = true
  }

  private jumpAddContact() {
    WecomUtil.instance.navigateToAddCustomer({
      success: (res) => {
        this.$message.success(res.errMsg)
      },
      fail: (res) => {
        this.$message.error(ObjectUtil.toString(res))
      }
    })
  }

  private async onTagConfirm(tags: ITagData[]) {
    if (tags.length === 0 || !this.wecomCustomerData.customerId) {
      return
    }
    const { data } = await SystemApi.setTag(
      TagType.Customer,
      [this.wecomCustomerData.customerId],
      tags.map(tag => tag.id)
    )
    if (data) {
      this.wecomCustomerData.tags = tags
    }
  }

  private sendMiniProgram() {
    WecomUtil.instance.sendChatMessage({
      msgtype: 'miniprogram',
      miniprogram: {
        appid: Constant.MINI_PROGRAM_APP_ID,
        title: '天下女人研习社',
        imgUrl: 'https://assets.hervillageclub.com/logo.png',
        page: 'packages/home/dashboard/index.html'
      },
      success: (res) => {
        this.$message.success(res.errMsg)
      },
      fail: (res) => {
        this.$message.error(ObjectUtil.toString(res))
      }
    })
  }

  private sendMessage(phoneNumber: string) {
    window.location.href = `sms:${phoneNumber}`
  }

  private call(phoneNumber: string) {
    window.location.href = `tel:${phoneNumber}`
  }
}
