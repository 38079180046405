





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IClueData, ICustomerData } from '@/api/types'
import TextUtil from '@/utils/text'
import CustomerApi from '@/api/customer'
import Constant from '@/constant/constant'
import MobileClueItem from '@/views/wecom/mobile-entry/customer-clue/ClueItem/index.vue'
import ClueDetailDrawer from '@/views/wecom/mobile-entry/drawers/clue-detail/index.vue'
import CreateClueDrawer from '@/views/wecom/mobile-entry/drawers/create-clue/index.vue'
import Empty from '@/components/Empty/index.vue'
import DateUtil from '@/utils/date'

@Component({
  name: 'MobileCustomerClue',
  components: {
    Empty,
    CreateClueDrawer,
    ClueDetailDrawer,
    MobileClueItem
  }
})
export default class extends Vue {
  @Prop({
    default: function(): ICustomerData {
      return {
        contactWayDictIds: [],
        contactWayTitles: [],
        contactWays: [],
        tags: [],
        identity: {
          list: []
        },
        id: ''
      }
    }
  }) private customerData!: ICustomerData

  @Watch('customerData', { immediate: true })
  private onCustomerDataChanged(data: ICustomerData, oldData: ICustomerData) {
    if (data?.id !== oldData?.id && TextUtil.isNotEmpty(data?.id)) {
      this.refreshClues()
    }
  }

  private clueList: IClueData[] = []

  private currentClue: IClueData = {
    feiyuClue: {},
    id: ''
  }

  private onlyShowFollowClues = false
  private isCreateClueShow = false
  private isClueDetailShow = false

  private async refreshClues() {
    if (!this.customerData.id) {
      return
    }
    const { data } = await CustomerApi.getCustomerClues({
      customerId: this.customerData.id
    }, {
      clueStatusTypes: this.onlyShowFollowClues ? [Constant.ClueStatus.Following.value] : undefined
    })
    if (data) {
      this.clueList = data.items
    }
  }

  private onOnlyFollowCluesChange() {
    this.refreshClues()
  }

  private onClueCreate() {
    this.refreshClues()
  }

  private onClueItemClick(clue: IClueData) {
    this.isClueDetailShow = true
    this.currentClue = clue
  }

  private onClueInvalid(index?: number, remarks = '') {
    let tempIndex = -1
    if (index !== undefined) {
      tempIndex = index
    } else {
      tempIndex = this.clueList.indexOf(this.currentClue)
    }
    this.$set(this.clueList, tempIndex, {
      ...this.clueList[tempIndex],
      clueStatus: Constant.ClueStatus.Invalid.value,
      lastChangeRemark: remarks
    })
  }

  private onOrderFail(index?: number, remarks = '') {
    let tempIndex = -1
    if (index !== undefined) {
      tempIndex = index
    } else {
      tempIndex = this.clueList.indexOf(this.currentClue)
    }
    this.$set(this.clueList, tempIndex, {
      ...this.clueList[tempIndex],
      clueStatus: Constant.ClueStatus.OrderFailed.value,
      lastChangeRemark: remarks
    })
  }

  private onOrderSucceed() {
    this.refreshClues()
  }

  private onClueFollow(index?: number, followTime = Date.now(), remarks = '') {
    let tempIndex = -1
    if (index !== undefined) {
      tempIndex = index
    } else {
      tempIndex = this.clueList.indexOf(this.currentClue)
    }
    this.$set(this.clueList, tempIndex, {
      ...this.clueList[tempIndex],
      clueStatus: Constant.ClueStatus.Following.value,
      lastFollowAt: DateUtil.dateFormat(followTime),
      lastChangeRemark: remarks
    })
  }

  private handleDrawerClose(done: Function) {
    done()
  }
}
