





















import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import MarketingApi from '@/api/marketing'
import { IOrderData, IWecomCustomerData } from '@/api/types'
import TextUtil from '@/utils/text'
import MobileOrderItem from '@/views/wecom/mobile-entry/customer-order/OrderItem/index.vue'
import OrderDetailDrawer from '@/views/wecom/mobile-entry/drawers/order-detail/index.vue'
import Empty from '@/components/Empty/index.vue'

@Component({
  name: 'MobileCustomerOrder',
  components: {
    Empty,
    OrderDetailDrawer,
    MobileOrderItem
  }
})
export default class extends Vue {
  private showOrderDetailDialog = false
  private orderList: IOrderData[] = []
  private currentOrder: IOrderData = {
    id: '',
    products: []
  }

  @Prop({
    default: function(): IWecomCustomerData {
      return {
        followUsers: [],
        tags: [],
        id: '',
        mobiles: []
      }
    }
  }) private wecomCustomerData!: IWecomCustomerData

  @Watch('wecomCustomerData', { immediate: true })
  private onWecomCustomerDataChanged(data: IWecomCustomerData, oldData: IWecomCustomerData) {
    if (data?.id !== oldData?.id && TextUtil.isNotEmpty(data?.id)) {
      this.refreshOrders()
    }
  }

  private onOnlyWaitConfirmOrdersChange() {
    this.refreshOrders()
  }

  private async refreshOrders() {
    if (!this.wecomCustomerData.customerId) {
      return
    }
    const { data } = await MarketingApi.orderList({
      customerId: this.wecomCustomerData.customerId
    })
    if (data?.items) {
      this.orderList = data.items
    }
  }

  private onOrderItemClick(order: IOrderData) {
    this.showOrderDetailDialog = true
    this.currentOrder = order
  }
}
