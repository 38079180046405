








































import { Vue, Component } from 'vue-property-decorator'
import WecomUtil from '@/utils/wecom'
import TextUtil from '@/utils/text'
import Constant from '@/constant/constant'
import { ICustomerData, IWecomCustomerData } from '@/api/types'
import MobileWecomProfile from '@/views/wecom/mobile-entry/wecom-profile/index.vue'
import MobileStudyTimeline from '@/views/wecom/mobile-entry/study-timeline/index.vue'
import MobileCustomProfile from '@/views/wecom/mobile-entry/customer-profile/index.vue'
import MobileCustomerOrder from '@/views/wecom/mobile-entry/customer-order/index.vue'
import MobileCustomerJourney from '@/views/wecom/mobile-entry/customer-journey/index.vue'
import MobileCustomerClue from '@/views/wecom/mobile-entry/customer-clue/index.vue'
import MobileCustomerIdentity from '@/views/wecom/mobile-entry/customer-identity/index.vue'

@Component({
  name: 'MobileEntry',
  components: {
    MobileCustomerIdentity,
    MobileCustomerClue,
    MobileCustomerJourney,
    MobileCustomerOrder,
    MobileCustomProfile,
    MobileStudyTimeline,
    MobileWecomProfile
  }
})
export default class extends Vue {
  private activeName = 'first'
  // todo test 天女测试-2
  // private userId = 'wmMtZ1XQAAqzsy-fnL7njtTi1r5XKvkQ'
  private userId = ''

  private wecomCustomerData: IWecomCustomerData = {
    followUsers: [],
    tags: [],
    id: '',
    mobiles: []
  }

  private customerData: ICustomerData = {
    contactWayDictIds: [],
    contactWayTitles: [],
    contactWays: [],
    identity: {
      list: []
    },
    tags: []
  }

  async created() {
    const success = await this.getWecomUserId()
    if (!success) {
      // todo 跳转回首页
    }
  }

  async getWecomUserId(): Promise<boolean> {
    if (!WecomUtil.isWxWorkEnv()) {
      this.$message.warning('请在企微APP内使用此功能')
      return false
    }
    const entryRes = await WecomUtil.instance.getContext()
    if (TextUtil.isEmpty(entryRes?.entry)) {
      this.$message.warning(`获取入口信息失败：${entryRes?.errMsg}`)
      return false
    }
    const entry = entryRes.entry
    if (entry !== Constant.WECOM_ENTRY_TYPES.SingleChatTools &&
      entry !== Constant.WECOM_ENTRY_TYPES.ContactProfile) {
      this.$message.warning('本功能需通过联系人详情页或外部单聊工具栏进入方可使用')
      return false
    }
    let contactRes
    try {
      contactRes = await WecomUtil.instance.getCurExternalContact()
    } catch (e) {
      let tips = e?.errMsg
      if (e?.errMsg === 'getCurExternalContact:permission denied') {
        tips = '无权限'
      }
      this.$message.warning(`获取联系人信息失败：${tips}`)
      return false
    }
    if (TextUtil.isEmpty(contactRes.userId)) {
      this.$message.warning(`获取联系人信息失败：${contactRes?.errMsg}`)
      return false
    }
    this.userId = contactRes.userId
    return true
  }

  private onWecomCustomerDataUpdate(data: IWecomCustomerData) {
    this.wecomCustomerData = data
  }

  private onCustomerDataUpdate(data: ICustomerData) {
    this.customerData = data
  }
}
