












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'VuePickerHeader'
})
export default class extends Vue {
  @Prop({ default: '' }) private cancelText!: string
  @Prop({ default: '' }) private confirmText!: string
  @Prop({ default: '' }) private title!: string

  private cancel() {
    this.$emit('cancel')
  }

  private confirm() {
    this.$emit('confirm')
  }
}
