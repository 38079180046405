

































import { Vue, Component, Prop } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import { IOrderData } from '@/api/types'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import Constant from '@/constant/constant'

@Component({
  name: 'MobileOrderItem',
  components: {
    LensSpan,
    MobileProductItem,
    ListTile
  }
})
export default class extends Vue {
  @Prop({
    default: function(): IOrderData {
      return {
        products: []
      }
    },
    required: true
  }) private order!: IOrderData

  private get statusAmountLabel() {
    let label = ''
    switch (this.order.orderStatus) {
      case Constant.OrderStatus.WaitPay.value:
        label = '应收金额'
        break
      case Constant.OrderStatus.Paying.value:
        label = '待收金额'
        break
      case Constant.OrderStatus.Refunded.value:
        label = '退款金额'
        break
    }
    return label
  }

  private get statusAmount() {
    let amount = 0
    switch (this.order.orderStatus) {
      case Constant.OrderStatus.WaitPay.value:
        amount = this.order.payableAmount ?? 0
        break
      case Constant.OrderStatus.Paying.value:
        amount = this.$parseFloat(this.order.payableAmount) - this.$parseFloat(this.order.paidAmount)
        break
      case Constant.OrderStatus.Refunded.value:
        amount = this.order.refundAmountAll ?? 0
        break
    }
    return amount
  }

  private onClueItemClick() {
    this.$emit('click')
  }
}
