





















import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'MobileStudyTimeline'
})
export default class extends Vue {
  private activities = [{
    content: '《了不起的孩子》',
    timestamp: '2022-03-04 14:23:13 开始学习，用时 14:32',
    lessons: []
  }, {
    content: '《大脑的一天》 ',
    timestamp: '2018-04-13 14:23:13 开始学习，用时 14:32',
    lessons: [
      '02.保险产品+生态链 | 王润东、管清友',
      '02.保险产品+生态链 | 王润东、管清友'
    ]
  }, {
    content: '杨澜专访《保险灼见》',
    timestamp: '2018-04-11 14:23:13 开始学习，用时 14:32',
    lessons: []
  }]
}
