

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICustomerData } from '@/api/types'
import Empty from '@/components/Empty/index.vue'

@Component({
  name: 'MobileCustomerJourney',
  components: { Empty }
})
export default class extends Vue {
  @Prop({
    default: function(): ICustomerData {
      return {
        contactWayDictIds: [],
        contactWayTitles: [],
        contactWays: [],
        identity: {
          list: []
        },
        tags: []
      }
    }
  }) private customer!: ICustomerData
}
