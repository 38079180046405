var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clues-container"},[_c('div',{staticClass:"switch-container"},[_c('el-switch',{attrs:{"active-text":"只看跟进中的线索","inactive-text":""},on:{"change":_vm.onOnlyFollowCluesChange},model:{value:(_vm.onlyShowFollowClues),callback:function ($$v) {_vm.onlyShowFollowClues=$$v},expression:"onlyShowFollowClues"}})],1),_c('el-col',{staticStyle:{"flex":"1","overflow-y":"scroll"}},[(_vm.clueList.length > 0)?_c('el-col',_vm._l((_vm.clueList),function(clue,index){return _c('mobile-clue-item',{key:clue.id,attrs:{"clue":clue},on:{"onClueItemClick":_vm.onClueItemClick,"orderFail":function (remarks) {
          _vm.onOrderFail(index,remarks)
        },"clueInvalid":function (remarks) {
          _vm.onClueInvalid(index,remarks)
        },"orderSuccess":_vm.onOrderSucceed,"clueFollow":function (followTime,remarks) {
          _vm.onClueFollow(index,followTime,remarks)
        }}})}),1):_c('empty',{on:{"retry":_vm.refreshClues}})],1),_c('div',{staticClass:"button-container"},[_c('el-button',{staticStyle:{"width":"80%"},attrs:{"round":"","type":"primary"},on:{"click":function($event){_vm.isCreateClueShow=true}}},[_vm._v("新建线索")])],1),_c('create-clue-drawer',{attrs:{"visible":_vm.isCreateClueShow,"customer-data":_vm.customerData},on:{"close":function($event){_vm.isCreateClueShow=false},"clueCreate":_vm.onClueCreate}}),_c('clue-detail-drawer',{attrs:{"visible":_vm.isClueDetailShow,"clue-id":_vm.currentClue.id},on:{"close":function($event){_vm.isClueDetailShow=false},"orderFail":function (remarks) {
      _vm.onOrderFail(undefined,remarks)
    },"clueInvalid":function (remarks) {
      _vm.onClueInvalid(undefined,remarks)
    },"orderSuccess":function (remarks) {
      _vm.onOrderSucceed(undefined,remarks)
    },"clueFollow":function (remarks) {
      _vm.onClueFollow(undefined,remarks)
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }